<template>
    <div>
        <Navbar />
        <v-row justify="center" class="ma-0">
            <router-link :to="{ name: 'login' }"><v-btn text class="mx-0 mt-5 text-none plain grey--text">{{ $t("passwordRecovery.success") }} <span class="primary--text ml-1">{{ $t("passwordRecovery.login") }}</span></v-btn></router-link>
        </v-row>
    </div>
</template>

<script>
import Navbar from '@/components/partials/Navbar'

    export default {
        name: "PasswordRecoverySuccess",
        components: {Navbar}
    }
</script>

<style lang="scss" scoped>

</style>